export const PRODUCTION = true;

export const API_BASE_URL = PRODUCTION
  ? "https://api.200mint.padelsociety.xyz"
  : "http://localhost:4000";

export const WEB3_RPC = {
  137: "https://polygon-rpc.com",
  80001: "https://matic-mumbai.chainstacklabs.com",
};

export const PAYPAL = {
  APP: "Padel Society",
  CLIENT_ID:
    "AQw7utoY1GrD9LOvSRwo0f80EBi1Jx3JI8nZVuPYY6YUtXTSxFIRE7rHHJbfU0vTmXK0U7y5jEWT3gOQ",
};

export const PAYPAL_CONFIGURATION = {
  CURRENCY: "EUR",
  COMPONENTS: "buttons",
};

export const PAYMENT_METHODS = {
  PAYPAL: "PAYPAL",
  CRYPTO: "CRYPTO",
};
