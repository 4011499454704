import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect, connectRpc } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import styled from "styled-components";
import * as s from "./styles/globalStyles";
import logo from "./assets/images/logo512.png";
import { API_BASE_URL, PAYMENT_METHODS, PRODUCTION } from "./constansts";
import BuyWithPayPal from "./components/BuyWithPayPal";
import Footer from "./components/Footer";
import { ethers } from "ethers";
import Swal from "sweetalert2";
import packOne from "./assets/images/packs/1x.png";
import packThree from "./assets/images/packs/3x.png";
import packFive from "./assets/images/packs/5x.png";
import axios from "axios";

export const StyledButton = styled.button`
  border: none;
  background-color: var(--secondary);
  padding: 20px;
  font-weight: bold;
  color: var(--secondary-text);
  width: auto;
  font-size: 17px;
  font-family: "jockey one";
  cursor: pointer;
`;

export const NavButton = styled.a`
  border: none;
  background-color: transparent;
  font-family: "jockey one";
  text-shadow: none;
  color: black;
  padding: 0 10px;
  font-weight: bold;
  font-size: 20px;
  width: auto;
  cursor: pointer;
  position: absolute;
  right: 120px;
  top: 74px;
  text-decoration: auto;
`;

export const ConnectButton = styled.button`
  border: none;
  background-color: var(--secondary);
  padding: 20px;
  font-weight: bold;
  color: var(--secondary-text);
  width: auto;
  font-size: 17px;
  font-family: "jockey one";
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 53px;
`;

export const Logo = styled.img`
  position: absolute;
  left: 32px;
  top: 32px;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResponsiveWrapper = styled.div`
  background-color: var(--secondary);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  padding: 1.5rem 4rem 4rem 4rem;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const HeaderNav = styled.div`
  color: black;
  justify-content: right;
  align-items: right;
  width: 100%;
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  border: 4px dashed var(--secondary);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

export const Title = styled.h1`
  color: var(--accent);
  text-decoration: none;
  font-size: 80px;
  font-style: italic;
  text-align: center;
  @media screen and (max-width: 428px) {
    font-size: 32px;
  }
`;

export const StyledInput = styled.input`
  width: 15rem;
  height: 4rem;
  border: 4px solid var(--secondary);
  outline: none;
  padding: 0.5rem;
  font-size: 17px;
  font-family: "jockey one";
  background: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [erc721WalletAddress, setErc721WalletAddress] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [showPaypalSpinner, setShowPaypalSpinner] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    DISPLAY_COST_DOLLARS: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
  });

  const shortenEthAddress = (address) => {
    address = address
      ? address.slice(0, 6) + "..." + address?.slice(38, 42)
      : undefined;
    return address;
  };

  const checkIsValidErc721WalletAddress = () => {
    return erc721WalletAddress == null ||
      typeof erc721WalletAddress == "undefined" ||
      erc721WalletAddress.trim().length <= 0
      ? false
      : ethers.utils.isAddress(erc721WalletAddress);
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        maxFeePerGas: 300000000000,
        maxPriorityFeePerGas: 300000000000,
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        setClaimingNft(false);
        setFeedback(`Click buy to mint your NFT.`);
        Swal.fire({
          title: "Payment failed",
          text: "Sorry, something went wrong please try again later.",
          icon: "error",
          showConfirmButton: true,
        });
      })
      .then((receipt) => {
        savePromoCode(promoCode, blockchain.account);
        setClaimingNft(false);
        setFeedback(`Click buy to mint your NFT.`);
        dispatch(fetchData(blockchain.account));
        Swal.fire({
          title: "Payment successful",
          html: `Your payment has been taken in charge and the request will be executed shortly.<br> Check <a href='${
            PRODUCTION
              ? "https://opensea.io/account"
              : "https://testnets.opensea.io/account"
          }' target="_blank" rel="noopener noreferrer">OpenSea</a> to view your newly minted NFT!`,
          icon: "success",
          showConfirmButton: true,
        });
      });
  };

  const chooseNftPack = (amount) => {
    let newMintAmount = amount;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const isSelectedNftPack = (amount) => {
    return amount === mintAmount;
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getBlockchainData = () => {
    if (blockchain.smartContract !== null) {
      dispatch(fetchData());
    }
  };

  const savePromoCode = (promoCode, address) => {
    if (promoCode && promoCode.trim().length > 0) {
      try {
        axios
          .post(
            `${API_BASE_URL}/system/log`,
            {
              category: "PROMO_CODE",
              data: `Promo Code: ${promoCode} Address: ${address}`,
            },
            { withCredentials: false }
          )
          .then((_) => {})
          .catch((error) => {
            console.error("[savePromoCode] Error: ", error);
          });
      } catch (error) {
        console.error("[savePromoCode] Error: ", error);
      }
    }
  };

  const easterEgg = () => {
    if (promoCode && promoCode == "EASTEREGG") {
      savePromoCode("EASTEREGG", "0xF1B0c1C3b333a2AC7750ca203FA815Be2Ee18123");
    }
  };

  useEffect(() => {
    dispatch(connectRpc());
  }, [dispatch]);

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    getBlockchainData();
  }, [blockchain.smartContract]);

  return (
    <s.PageContainer>
      <s.Header>
        <s.Container
          ai={"left"}
          style={{
            backgroundColor: "var(--color_21)",
            display: "block",
            position: "relative",
          }}
        >
          <Logo
            src={logo}
            alt="Logo"
            height={"98px"}
            width={"98px"}
            onClick={easterEgg}
          />
          <NavButton href="https://www.padelsociety.xyz/">&lt; Back</NavButton>
          <ConnectButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            {blockchain.account
              ? shortenEthAddress(blockchain.account)
              : "Connect"}
          </ConnectButton>
        </s.Container>
      </s.Header>
      <s.Screen>
        <s.Container
          flex={1}
          ai={"center"}
          style={{ backgroundColor: "var(--primary)" }}
        >
          <s.SpacerSmall />
          <Title>
            Welcome to the most exclusive Padel Club in the world !!!
          </Title>
          <s.SpacerLarge />
        </s.Container>
        <s.Container flex={0} fd={"row"}>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 16,
              border: `4px solid var(${
                isSelectedNftPack(1) ? "--accent-secondary" : "--secondary"
              })`,
              marginRight: "0.5rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              chooseNftPack(1);
            }}
          >
            <s.ImagePack src={packOne} alt="1 NFT Pack" />1 NFT Pack
          </s.Container>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 16,
              border: `4px solid var(${
                isSelectedNftPack(3) ? "--accent-secondary" : "--secondary"
              })`,
              marginRight: "0.5rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              chooseNftPack(3);
            }}
          >
            <s.ImagePack src={packThree} alt="3 NFT Pack" />3 NFT Pack
          </s.Container>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 16,
              border: `4px solid var(${
                isSelectedNftPack(5) ? "--accent-secondary" : "--secondary"
              })`,
              marginRight: "0.5rem",
            }}
            onClick={(e) => {
              e.preventDefault();
              chooseNftPack(5);
            }}
          >
            <s.ImagePack src={packFive} alt="5 NFT Pack" />5 NFT Pack
          </s.Container>
        </s.Container>
        <s.SpacerLarge />
        <ResponsiveWrapper flex={1} ai={"center"}>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
            }}
          >
            {/*
            <s.Container
              flex={1}
              jc={"center"}
              ai={"center"}
              style={{
                width: "100%",
                backgroundColor: "var(--primary)",
                padding: 24,
                border: "4px solid var(--secondary)",
              }}
            >
              <s.TextTitle
                style={{ textAlign: "center", color: "var(--accent)" }}
              >
                The first 200 NFTs will be revealed in December 2022
              </s.TextTitle>
            </s.Container>
            */}
            <s.SpacerSmall />
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                NFT Collection: {shortenEthAddress(CONFIG.CONTRACT_ADDRESS)}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.Container
                  flex={0}
                  fd={"row"}
                  jc={"center"}
                  ai={"center"}
                  style={{ padding: "12px" }}
                >
                  <StyledButton
                    style={{ marginRight: "0.5rem" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setPaymentMethod(PAYMENT_METHODS.CRYPTO);
                      if (
                        blockchain.account === null ||
                        typeof blockchain.account === "undefined" ||
                        blockchain.account === ""
                      ) {
                        Swal.fire({
                          title: "Connect your wallet",
                          text: "Please connect your wallet using the 'connect' button above",
                          icon: "error",
                          showConfirmButton: true,
                        });
                      }
                      if (blockchain.errorMsg !== "") {
                        Swal.fire({
                          title: "Connection Error",
                          text: blockchain.errorMsg,
                          icon: "error",
                          showConfirmButton: true,
                        });
                      }
                    }}
                  >
                    Pay with Crypto
                  </StyledButton>
                  <StyledButton
                    onClick={(e) => {
                      e.preventDefault();
                      setPaymentMethod(PAYMENT_METHODS.PAYPAL);
                    }}
                  >
                    Pay with PayPal
                  </StyledButton>
                </s.Container>

                {paymentMethod === PAYMENT_METHODS.PAYPAL ? (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {mintAmount} NFT cost{" "}
                      {/* {mintAmount} {CONFIG.SYMBOL} costs{" "} */}
                      {CONFIG.DISPLAY_COST_DOLLARS * mintAmount}€.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledInput
                          style={{
                            marginBottom: "0.5rem",
                          }}
                          placeholder={"Add your Promo Code"}
                          value={promoCode}
                          onChange={(e) => {
                            e.preventDefault();
                            setPromoCode(e.target.value?.toUpperCase());
                          }}
                        />
                      </s.Container>
                      <StyledInput
                        placeholder={"ERC721 Wallet Address"}
                        style={{
                          marginBottom: "0.5rem",
                        }}
                        value={erc721WalletAddress}
                        onChange={(e) => {
                          e.preventDefault();
                          setErc721WalletAddress(e.target.value);
                        }}
                      />
                      <s.Container
                        style={{
                          paddingTop: "0.25rem",
                        }}
                      >
                        <BuyWithPayPal
                          address={erc721WalletAddress}
                          promoCode={promoCode}
                          nftAmount={mintAmount}
                          price={CONFIG.DISPLAY_COST_DOLLARS}
                          disabled={!checkIsValidErc721WalletAddress()}
                          spinner={showPaypalSpinner}
                          setSpinner={setShowPaypalSpinner}
                          savePromoCode={savePromoCode}
                        />
                      </s.Container>
                    </>
                  </>
                ) : (
                  <>
                    <s.TextTitle
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {mintAmount} NFT cost{" "}
                      {/* {mintAmount} {CONFIG.SYMBOL} costs{" "} */}
                      {CONFIG.DISPLAY_COST * mintAmount} {CONFIG.NETWORK.SYMBOL}
                      .
                    </s.TextTitle>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Excluding gas fees.
                    </s.TextDescription>
                    <s.SpacerXSmall />
                    {blockchain.account === null ||
                    typeof blockchain.account === "undefined" ||
                    blockchain.account === "" ? (
                      <s.Container ai={"center"} jc={"center"}>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          Connect to the {CONFIG.NETWORK.NAME} network
                        </s.TextDescription>
                        {blockchain.errorMsg !== "" ? (
                          <>
                            <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {blockchain.errorMsg}
                            </s.TextDescription>
                          </>
                        ) : null}
                      </s.Container>
                    ) : (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {feedback}
                        </s.TextDescription>
                        <s.SpacerMedium />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledInput
                            style={{
                              width: "19.542rem",
                            }}
                            placeholder={"Add your Promo Code"}
                            value={promoCode}
                            onChange={(e) => {
                              e.preventDefault();
                              setPromoCode(e.target.value?.toUpperCase());
                            }}
                          />
                        </s.Container>
                        <s.SpacerXSmall />
                        <s.Container ai={"center"} jc={"center"} fd={"row"}>
                          <StyledInput
                            placeholder={"ERC721 Wallet Address"}
                            value={
                              blockchain.account != null &&
                              typeof blockchain.account != "undefined" &&
                              blockchain.account != ""
                                ? blockchain.account
                                : ""
                            }
                            disabled={
                              blockchain.account != null &&
                              typeof blockchain.account != "undefined" &&
                              blockchain.account != ""
                                ? true
                                : false
                            }
                            readOnly={true}
                            style={{
                              marginRight: "0.5rem",
                            }}
                          />
                          <StyledButton
                            disabled={claimingNft ? 1 : 0}
                            onClick={(e) => {
                              e.preventDefault();
                              claimNFTs();
                              getData();
                            }}
                          >
                            {claimingNft ? "Minting..." : "Buy"}
                          </StyledButton>
                        </s.Container>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <s.SpacerSmall />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerLarge />
        <Footer />
      </s.Screen>
    </s.PageContainer>
  );
}

export default App;
