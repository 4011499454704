import Container from 'react-bootstrap/Container';
import * as s from "../styles/globalStyles";
function Footer() {
  return (
    <s.Footer>
        <s.Container
            flex={1}
            padding={24}
            ai={"left"}
            style={{ backgroundColor: "var(--primary)", fontSize: "13px", marginTop:"24px",
            lineHeight: "1.6em" }}>
            <p>
              2022 © by
              <span style={{color:"var(--accent)", }}> Padel Society S.r.l. - P.IVA 04627570163 </span>
              <br />All rights reserved.
            </p>

      </s.Container>
    </s.Footer>
  );
}

export default Footer;