import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import "./styles/reset.css";
import { PAYPAL, PAYPAL_CONFIGURATION } from "./constansts";

ReactDOM.render(
  <Provider store={store}>
    <PayPalScriptProvider
      options={{
        "client-id": PAYPAL.CLIENT_ID,
        currency: PAYPAL_CONFIGURATION.CURRENCY,
      }}
    >
      <App />
    </PayPalScriptProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
