import { PayPalButtons } from "@paypal/react-paypal-js";
import { SpinnerRoundFilled } from "spinners-react";
import Swal from "sweetalert2";
import { PAYPAL_CONFIGURATION, PRODUCTION } from "../constansts";

const BuyWithPayPal = ({
  price,
  nftAmount,
  address,
  disabled,
  spinner,
  setSpinner,
  promoCode,
  savePromoCode,
}) => {
  // Fix to pass values to createOrder callback, otherwise the changes are not refleted to values
  window.amount = nftAmount;
  window.account = address;
  window.price = nftAmount * price;
  window.promo = promoCode;

  return (
    <div style={{ display: "inline-flex" }}>
      <PayPalButtons
        style={{
          layout: "horizontal",
          shape: "rect",
          color: "black",
          height: 45,
        }}
        createOrder={(data, actions) => {
          setSpinner(true);
          return actions.order
            .create({
              purchase_units: [
                {
                  custom_id: `${window.amount}$$_&_$$${window.account}`,
                  amount: {
                    currency_code: PAYPAL_CONFIGURATION.CURRENCY,
                    value: window.price,
                  },
                },
              ],
            })
            .then((orderId) => {
              return orderId;
            });
        }}
        onApprove={async (data, actions) => {
          await actions.order.capture();
          savePromoCode(window.promo, window.account);
          setSpinner(false);
          Swal.fire({
            title: "Payment successful",
            html: `Your payment has been taken in charge and the request will be executed shortly.<br> Check <a href='${
              PRODUCTION
                ? "https://opensea.io/account"
                : "https://testnets.opensea.io/account"
            }' target="_blank" rel="noopener noreferrer">OpenSea</a> to view your newly minted NFT!`,
            icon: "success",
            showConfirmButton: false,
            timer: 10 * 1000,
          });
        }}
        onCancel={async (data, actions) => {
          setSpinner(false);
        }}
        onError={async (data, actions) => {
          setSpinner(false);
        }}
        disabled={disabled}
      />
      {spinner && (
        <SpinnerRoundFilled
          size={50}
          thickness={125}
          speed={180}
          color="var(--secondary)"
          style={{
            marginLeft: "0.5rem",
          }}
        />
      )}
    </div>
  );
};

export default BuyWithPayPal;
